:root {
  --color-primary: #006AF5;
  --color-text: #fff;
  --color-text-dark: #4a4a4a;

  --color-error: #e42d2d;
  --color-success: #37ed3b;
  --color-warning: #ecbf37;
  --color-info: #37aad5;

  --color-background-default: #202226;
  --color-background-default-opacity: rgba(33, 35, 38, .9);
  --color-background-content: #31343A;
  --color-background-paper: #202226;
  --color-background-paper-opacity: rgba(33, 35, 38, .9);
  --color-background-modal: rgba(50, 57, 72, 0.2);
  --color-background-modal-opacity: rgba(33, 35, 38, .9);

  --color-divider-default: #50545C;

  --color-sidenav-header-background: rgba(45, 83, 134, 0.4);

  --color-button-color: #FFFFFF;
  --color-button-hover: #50545C;
  --color-button-hover-color: #FFFFFF;
  --color-button-hover-focus: #ffdd75;
  --color-button-hover-active: #ffdd75;
  --color-button-focus: #ffdd75;
  --color-button-focus-color: #FFFFFF;
  --color-button-active: #ffdd75;
  --color-button-active-color: #4a4a4a;
  --color-button-disabled: #50545C;

  --color-tooltip-background: #50545C;

  --color-avatar-border: rgb(255, 221, 117);

  --color-textField-background: #31343A;
  --color-textField-border: #484F5A;
  --color-textField-color: #FFFFFF;
  --color-textField-label: #B9B9B9;
  --color-textField-focus-outline: #ffdd75;

  --color-select-options-background: rgba(72, 79, 90, .5);
  --color-select-options-border: #484F5A;

  --color-table-header: rgba(32, 34, 38, .9);
  --color-table-row: rgba(32, 34, 38, .9);
  --color-table-row-alt: rgba(49, 52, 58, .9);
  --color-table-row-hover: rgba(80, 84, 92, .9);
  --color-table-row-active: rgba(70, 74, 82, .9);
  --color-table-row-border: #556078;
  --color-table-row-color: #FFFFFF;

  --color-tabs-background: #4d4d4d;
  --color-tabs-color: #FFFFFF;
  --color-tabs-hover-background: #717171;
  --color-tabs-hover-color: #FFFFFF;
  --color-tabs-active-background: #ffdd75;
  --color-tabs-active-color: #FFFFFF;
  --color-tabs-active-hover-background: #ffdd75;
  --color-tabs-active-hover-color: #FFFFFF;

  --color-menu-background: rgba(72, 79, 90, 0.5);
  --color-menu-color: #FFFFFF;
  --color-menu-hover-border: #ffdd75;
  --color-menu-hover-background: #31343A;
  --color-menu-hover-color: #FFFFFF;

  --color-checkbox-background: #31343A;
  --color-checkbox-on: #ffdd75;
  --color-checkbox-off: #484F5A;

  --color-slate-variable-background: #202226;
  --color-slate-variable-border: #202226;
  --color-slate-variable-color: #FFFFFF;
  --color-slate-variable-hover-background: #202226;
  --color-slate-variable-input: #31343A;
  --color-slate-variable-icon: #767676;

  --color-overlay-background: #282b2f;
  --color-overlay-border: #202226;
  --color-overlay-grid: rgba(255, 255, 255, .2);
  --color-overlay-center-lines: rgba(255, 255, 255, .2);
  --color-overlay-preview-shroud: rgba(0, 0, 0, .7);
  --color-overlay-toggle-bar: rgba(0, 106, 245, .3);

  --color-snackbar-background: #202226;
  --color-snackbar-color: #FFFFFF;
  --color-snackbar-border: #202226;

  --content-border-radius: 16px;
  --card-border-radius: 16px;
  --tabs-border-radius: 8px;
  --menu-border-radius: 8px;
  --menuItem-border-radius: 5px;
  
  --multi-toggle-background: #31343A;
  --multi-toggle-color: #FFFFFF;
  --multi-toggle-slider-background: #ffdd75;
  --multi-toggle-selected-color: #FFFFFF;
  --multi-toggle-slider-shadow: none;
  --multi-toggle-border-radius: 8px;
  --multi-toggle-slider-border-radius: 6px;

  --swal-border-color: #ffdd75;
  --swal-title-background: rgba(45, 83, 134, 0.4);
  --swal-title-color: white;
  --swal-message-background: rgba(124, 124, 124, 0.3);
  --swal-message-color: white;
  --swal-buttons-background: rgba(35, 35, 35, 0.2);
  --swal-icon-background: rgba(113, 113, 113, 0.2);

  --footer-logo-filter: none;

  body {
    &.theme-light {
      --color-text: #000000;
    
      --color-background-default: #f1f1f1;
      --color-background-default-opacity: rgba(241, 241, 241, .9);
      --color-background-content: #FFFFFF;
      --color-background-paper: #f1f1f1;
      --color-background-default-opacity: rgba(241, 241, 241, .9);
      --color-background-modal: #f1f1f1;
      --color-background-modal-opacity: rgba(241, 241, 241, .9);
    
      --color-button-color: #000000;

      --color-table-header: rgba(241, 241, 241, .9);
      --color-table-row: rgba(241, 241, 241, .9);
      --color-table-row-alt: rgba(241, 241, 241, .9);
      --color-table-row-hover: rgba(48, 68, 108, .9);
      --color-table-row-active: rgba(48, 68, 108, .9);
      --color-table-row-border: #556078;
      --color-table-row-color: #556078;

      --footer-logo-filter: invert(1);
    }

    &.theme-blue {
      --theme: blue;

      --color-primary: #006AF5;
      --color-text: #FFFFFF;
    
      --color-error: #e42d2d;
      --color-success: #37ed3b;
      --color-warning: #ecbf37;
      --color-info: #37aad5;
    
      --color-background-default: #2d4066;
      --color-background-default-opacity: rgba(45, 64, 102, .9);
      --color-background-content: #516286;
      --color-background-paper: #2d4066;
      --color-background-paper-opacity: rgba(45, 64, 102, .9);
      --color-background-modal: #2d4066;
      --color-background-modal-opacity: rgba(45, 64, 102, .9);
    
      --color-table-header: rgba(45, 64, 102, .9);
      --color-table-row: rgba(45, 64, 102, .9);
      --color-table-row-alt: rgba(45, 64, 102, .9);
      --color-table-row-hover: rgba(48, 68, 108, .9);
      --color-table-row-active: rgba(48, 68, 108, .9);
      --color-table-row-border: #556078;
    }    
  }
}