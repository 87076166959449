@import "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap";
@import url('https://fonts.googleapis.com/css2?family=Megrim&display=swap');

@import "variables";

html, body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

* {
  vertical-align: baseline;
  font-weight: inherit;
  font-family: inherit;
  font-style: inherit;
  font-size: 100%;
  border: 0 none;
  outline: 0;
  padding: 0;
  margin: 0;
}

html {
  font-family: Roboto, sans-serif;
  background-color: white;
  color: var(--color-text);

  body {
    display: flex;
    align-items: center;
    justify-content: center;

    #root {
      width: 100%;
      height: 100%;
      background: url('https://i.redd.it/nx2iz5e7rdt51.png') no-repeat center center fixed;
      background-size: cover;
      position: relative;
      z-index: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .ptr__pull-down {
        display: flex;
        align-items: center;
        justify-content: center;

        .ptr__pull-down--loading {
          .lds-ellipsis {
            > div {
              background-color: var(--color-text);
            }
          }
        }

        .ptr__pull-down--pull-more {
          color: white;
        }
      }

      .ptr__children {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .swiper-pagination {
        margin-bottom: -10px;
        padding: 8px 0 5px 0;
        backdrop-filter: blur(10px);
        background: var(--color-background-default);
        box-shadow: 0px -24px 38px 3px rgba(0,0,0,0.14) , 0px -9px 46px 8px rgba(0,0,0,0.12) , 0px -11px 15px -7px rgba(0,0,0,0.2) ;
      }  
  
    }
  }
}
