.anchorButton {
  display: inline-flex;
  cursor: pointer;

  * {
    pointer-events: none;
  }
}

.button {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  background: none;
  color: var(--color-button-color);
  border: 0;
  height: 40px;
  border: 1px solid transparent;
  border-radius: 8px;
  font-size: 16px;
  outline: 2px solid transparent;
  outline-offset: 5px;
  padding: 7px 15px;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  overflow: hidden;

  &.contained {
    background: var(--color-button-active);
    color: var(--color-button-active-color);
  }
  &.outlined {
    background: none;
    outline-color: var(--color-button-active);
    color: var(--color-button-active);
  }
  &.text {
    background: none;
    color: var(--color-button-color);
  }

  &.fullwidth {
    width: 100%;
  }

  &.small {
    height: 30px;
    font-size: 12px;
    padding: 3px 8px;
  }

  &.large {
    height: 50px;
    font-size: 18px;
    padding: 10px 20px;
  }

  * {
    pointer-events: none;
  }
  
  span {
    display: flex;
    align-items: center;
    gap: 17px;
    text-decoration: none;
  }

  .iconContainer {
    position: relative;
  }

  .icon {
    display: inline-block;
  }

  .badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    min-width: 6px;
    min-height: 6px;
    padding: 0 3px;
    transform: translate(50%, -50%);
    color: white;
    font-size: 10px;
    z-index: 1;

    &::before {
      content: "";
      z-index: -2;
      display: block;
      width: calc(100% + 5px);
      height: calc(100% + 5px);
      background: var(--color-background-default);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 6px;
      transition: all 0.2s ease-in-out;
    }

    &::after {
      content: "";
      z-index: -1;
      display: block;
      width: calc(100%);
      height: calc(100%);
      background: var(--color-button-focus);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 6px;
    }
  }

  .dd {
    margin-left: 17px;
    display: flex;
    align-items: center;
  }

  &:hover:not(.disabled) {
    background-color: var(--color-button-hover);
    color: var(--color-button-hover-color);
    outline: 2px solid var(--color-button-hover);
    outline-offset: 2px;

    &.contained {
      background-color: var(--color-button-hover-active);
      outline: 2px solid var(--color-button-hover-active);
      outline-offset: 2px;
    }
  }

  &.focus,
  &:focus {
    background-color: var(--color-button-hover);
    border-color: var(--color-background-default);
    outline: 2px solid var(--color-button-focus);
    outline-offset: 2px;
  }

  &:hover,
  &.focus,
  &:focus {
    .badge {
      &::before {
        background: var(--color-button-hover);
        color: var(--color-button-focus-color);
      }
    }
  }

  &.active {
    background-color: var(--color-button-active) !important;
    cursor: default;
    color: var(--color-button-active-color);

    &:hover {
      outline: 2px solid var(--color-button-focus);
      outline-offset: 2px;  
    }

    .badge {
      color: var(--color-background-default);

      &::before {
        background: var(--color-button-focus);
      }

      &::after {
        background: #FFFFFF;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background: none;
    border-color: transparent;
    outline: none;

    &.contained {
      background: var(--color-button-hover) !important;
    }
    &.outlined {
      background: none !important;
      outline-color: var(--color-button-hover) !important;
    }

    &.focus,
    &:focus {
      outline: none !important;
    }  
  }
}
