.App {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .login {
    display: flex;
    flex-direction: column;

    .logo {
      margin: 0 auto 20px auto;
      filter: drop-shadow(0 0 0.1rem rgba(0, 0, 0, 0.5));
    }

    .loginTitle {
      margin: 0 auto 50px auto;
      font-size: 24px;
      font-weight: bold;
      color: #fff;
      text-align: center;

      h2 {
        font-size: 13px;
        font-weight: bold;
        color: #fff;
      }
    }

    .buttons {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      gap: 10px;

      .button {
        background-color: rgba(#fff, .4);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2px;
        border-radius: 100px;
        border: 2px solid rgba(#fff, .6);
        transition: all .3s ease-in-out;
        cursor: pointer;

        svg {
          padding: 20px;
          width: 30px !important;
          height: 30px !important;
          border-radius: 100px;
          border: 2px solid rgba(#fff, .6);
          background: rgba(#fff, .4);

          path {
            fill: #fff;
          }
        }

        &:hover {
          background-color: rgba(#fff, .6);
        }

        &:nth-child(1) {
          svg {
            background-color: #3049c7;
          }
        }

        &:nth-child(2) {
          svg {
            background-color: #a970ff;
          }

          z-index: 1;
          transform: scale(1.5);
        }

        &:nth-child(3) {
          svg {
            background-color: #19d66b;
          }
        }

        span {
          padding: 10px 0;
          font-size: 13px;
          color: #fff;
        }
      }
    }
  }

  .appTitle {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    font-size: 15px;
    font-weight: 900;
    width: calc(100% - 40px);
    background: rgba(255, 255, 255, .2);
    box-shadow: 0px 24px 38px 3px rgba(0,0,0,0.14) , 0px 9px 46px 8px rgba(0,0,0,0.12) , 0px 11px 15px -7px rgba(0,0,0,0.2) ;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);

    h1, div {
      display: flex;
      gap: 10px;
      align-items: center;

      .loggedInLogo {
        margin-left: -10px;
        filter: drop-shadow(0 0 0.1rem rgba(0, 0, 0, 0.5));
      }
    }
  }

  .pullToRefresh {
    padding-top: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    height: 40px;
  }

  .bullet {
    height: 26px;
    width: 26px;
    border: 2px solid var(--color-avatar-border);
  }

  .swiper {
    width: 100%;
    flex: 1;
  }

  .channel {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    text-shadow: 20px 30px -10px rgb(38, 57, 77);

    .avatar {
      width: 50px;
      height: 50px;
      border: 3px solid var(--color-avatar-border);
      border-radius: 50%;
      box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    }
  }

  .swiperSlide {
    display: flex;
    flex-direction: column;

    .cardsContainer {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 20px;

      .cardContainer {
        overflow: initial;

        .currencyCard {
          aspect-ratio: 710/405;
          max-width: 405px;
          margin: 0 auto;
          background-color: rgba(#fff, .4);
          -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
          border-radius: 12px;
          border: 3px solid rgba(#fff, .6);
          padding: 20px;
          position: relative;
          overflow: hidden;
          box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;

          .cardTitle {
            font-size: 30px;
            color: var(--color-text-dark);
            position: absolute;
            top: 10px;
            left: 0px;
            width: 100%;
            text-align: center;
            font-family: 'Megrim', cursive;
            font-weight: 900;

            &::first-letter {
              text-transform: uppercase;
            }
          }

          .amount {
            display: flex;
            align-items: center;
            gap: 10px;
            opacity: .8;
            font-size: 30px;
            font-weight: 900;
            color: var(--color-text-dark);
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
            background: rgba(#fff, .8);
            padding: 0 10px;
            width: calc(100% - 20px);

            span {
              font-size: 13px;
              font-family: 'Megrim', cursive;
            }
          }

          .name {
            font-size: 60px;
            font-weight: 900;
            color: #fff;
            position: absolute;
            bottom: -24px;
            right: -5px;
            color: var(--color-text-dark);
            opacity: .4;
            font-family: 'Megrim', cursive;

            &::first-letter {
              font-size: 100px;
            }
          }

          .username {
            font-weight: 500;
            color: #fff;
            filter: drop-shadow(-1px -1px 0px rgba(255, 255, 255, 0.3)) drop-shadow(1px 1px 0px rgba(0, 0, 0, 0.8));
            position: absolute;
            bottom: 15px;
            left: 20px;
          }
        }

        .daily {
          position: relative;
          z-index: 1;
          margin: 0 auto;
          -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
          background: rgba(#fff, .75);
          color: blue;
          max-width: 405px;
          text-align: center;
          padding: 2px 0;
          border-radius: 10px 10px 0 0;
        }      
      }
    }
  }
}
