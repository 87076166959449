.wrapper {
  display: flex;
  align-items: center;
}

.root {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 9999999999;
  
  .tooltip {
    display: inline;
    position: relative;
    white-space: pre-wrap;
    pointer-events: none;

    > span {
      position: absolute;
      background: var(--color-tooltip-background);
      min-height: 28px;
      padding: 1ch 1.5ch;
      min-width: 3em;
      max-width: 21em;
      width: max-content;
      opacity: 0;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, .4);
      transition: opacity .2s ease-in-out;
      border-radius: 4px;
      font-size: 12px;
      white-space: pre-wrap;  
    }
  }

  .open {
    > span {
      opacity: 1;
    }
  }
}
